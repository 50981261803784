import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { getInitials } from "lib/functions/get-initials";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { getQuizResultAsync } from "store/reducers/game/quiz-result/quiz-result.action";
import { Avatar, AvatarFallback, AvatarImage } from "ui/components/ui/avatar";
import { Button } from "ui/components/ui/button";
import MobilePageLayout from "ui/layout/mobile-page-layout";

interface GameOverPageProps {
  setContinueWatching: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function GameOverPage({
  setContinueWatching,
}: GameOverPageProps) {
  const { user } = useAppSelector((state) => state.user);
  const { game } = useAppSelector((state) => state.game);
  const { quizResult, status } = useAppSelector((state) => state.quizResult);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getQuizResultAsync({ gameId: game?.id! }));
  }, [dispatch, game?.id]);

  return (
    <MobilePageLayout
      title="Home"
      isLoading={status === "loading"}
      className="bg-gradient-to-b from-primary to-[#282061]">
      <div className="flex min-h-screen flex-col items-center bg-primary p-4">
        {/* Appbar */}
        <div className="flex w-full justify-end text-left">
          <h4 className="font-family-app-label w-fit text-sm tracking-wider text-secondary">
            Jeetlo
          </h4>
        </div>

        <div className="flex w-full flex-grow flex-col">
          <div className="my-10 flex h-3/5 min-h-[700px] w-full flex-col place-content-center items-center gap-6 rounded-md bg-card p-10">
            <h1 className="text-2xl font-semibold">Game Over</h1>

            <div className="flex h-2/3 w-full flex-col place-content-center items-center gap-4 rounded-2xl border border-border py-32 sm:p-32">
              <Avatar className="h-20 w-20">
                <AvatarImage src={user?.picture} />
                <AvatarFallback>{getInitials(user?.name)}</AvatarFallback>
              </Avatar>
              <p className="py-2 text-lg text-primary">
                You got {quizResult?.totalCorrectAnswerCount} of{" "}
                {quizResult?.totalQuestionCount} correct!
              </p>
              {quizResult?.isWinner ? (
                <p>You won the game</p>
              ) : (
                <p>That’s your best so far</p>
              )}
            </div>
            {quizResult?.isWinner ? (
              <Link to={`/game/${game?.id}/winners`}>
                <Button>Check Winners</Button>
              </Link>
            ) : (
              <Button
                variant={"link"}
                onClick={() => setContinueWatching(true)}>
                Continue Watching
              </Button>
            )}
          </div>
        </div>
      </div>
    </MobilePageLayout>
  );
}
