import React from "react";
import { useRouteError } from "react-router-dom";
import MobilePageLayout from "ui/layout/mobile-page-layout";

export default function ErrorPage() {
  const error = useRouteError() as any;

  return (
    <MobilePageLayout
      className="relative bg-gradient-to-b from-primary to-[#282061]"
      innerClassName="min-h-screen bg-primary  text-secondary border border-secondary/5 rounded">
      <div className="flex h-screen w-full flex-col place-content-center items-center">
        <div className="py-4 text-center text-xl font-medium">
          <h1 className="font-family-app-label text-4xl tracking-wider text-secondary">
            Jeetlo
          </h1>
        </div>
        <div className="my-40 rounded border-l-4 border-yellow-500 bg-slate-50/10 p-10 text-secondary">
          <h1 className="font-semibold">Oops!</h1>
          <p className="font-medium">
            Sorry, an unexpected error has occurred.
          </p>
          <p className="font-light">
            <i>{error.statusText || error.message}</i>
          </p>
        </div>
      </div>
    </MobilePageLayout>
  );
}
