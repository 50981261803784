import React from "react";
import { Link } from "react-router-dom";
import Logo from "ui/components/svgs/logo.svg";
import { Button } from "ui/components/ui/button";
import MobilePageLayout from "ui/layout/mobile-page-layout";

export default function GameGuidelinePage() {
  return (
    <MobilePageLayout>
      <div className="m-4 flex flex-col items-center md:mt-10">
        <Logo className="mx-auto h-60 w-60" />
        <div className="py-8 text-center text-xl font-medium">
          <h1 className="">Welcome To</h1>
          <h1 className="font-family-app-label text-3xl">JEETLO</h1>
        </div>

        <ul className="mx-auto list-disc py-10 pl-1">
          <li>Join in everyday to play the game.</li>
          <li>
            There will be total 12 Questions with each question having 1 correct
            answers
          </li>
          <li>You’ll get 10 sec to answer each question</li>
          <li>You win by answering all question</li>
          <li>The total pool prize will be divided between the winners</li>
        </ul>

        <div className="mt-10 flex w-full place-content-center items-center">
          <Link to={"/"} className="w-full max-w-sm">
            <Button className="w-full">Continue</Button>
          </Link>
        </div>
      </div>
    </MobilePageLayout>
  );
}
