import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { cn } from "lib/utils";
import React, { useEffect, useState } from "react";
import { getQuestionAsync } from "store/reducers/game/question/question.action";
import { AnswerStatus } from "ui/components/compounds/answer-status";
import MobilePageLayout from "ui/layout/mobile-page-layout";
import NextQuestionWaitTimer from "./next-question-wait-timer";
import ResultWaitTimer from "./result-wait-timer";
import { setQuestion } from "store/reducers/game/question/question.reducer";
import GameOverPage from "./game-over/game-over.page";
import { useNavigate } from "react-router-dom";

type QuestionBody = "question" | "resultTimer" | "nextQuestionTimer";

export type QuizStatus = "PENDING" | "OVER";

const QUESTION_TIMER_COUNTDOWN = 10;
const RESULT_TIMER_COUNTDOWN = 5;
const NEXT_QUESTION_TIMER_COUNTDOWN = 3;
const ANSWER_REVEAL_COUNTDOWN = 5;

export default function QuestionPage() {
  const [selectedOption, setSelectedOption] = useState<string | undefined>();
  const [revealAnswer, setRevealAnswer] = useState(false);
  const game = useAppSelector((state) => state.game);
  // const [quizStatus, setQuizStatus] = useState<QuizStatus>("PENDING");
  const [isGameOver, setIsGameOver] = useState(false);
  const { question, status, index, nextQuestion } = useAppSelector(
    (state) => state.question
  );
  const [questionState, setQuestionState] = useState<QuestionBody>("question");
  const [countdown, setCountDown] = useState(QUESTION_TIMER_COUNTDOWN);
  const gameId = game?.game?.id;
  const [isContinueWatching, setIsContinueWatching] = useState(false);
  const router = useNavigate();

  const dispatch = useAppDispatch();

  // Get first question
  useEffect(() => {
    if (status === "idle" && gameId && question === undefined) {
      setRevealAnswer(false);
      dispatch(getQuestionAsync({ index: index, gameId: gameId! }));
    }
  }, [dispatch, gameId, question, status, index]);

  if (isGameOver && !isContinueWatching) {
    return (
      <GameOverPage
        setContinueWatching={(value) => {
          setIsContinueWatching(value);
          if (nextQuestion) {
            setQuestionState("nextQuestionTimer");
            setCountDown(RESULT_TIMER_COUNTDOWN);
          }
        }}
      />
    );
  }

  return (
    <MobilePageLayout
      title="Home"
      isLoading={status === "loading"}
      className="bg-gradient-to-b from-primary to-[#282061]">
      <div className="flex min-h-screen flex-col items-center bg-primary p-4">
        {/* Appbar */}
        <div className="flex w-full justify-end text-left">
          <h4 className="font-family-app-label w-fit text-sm tracking-wider text-secondary">
            Jeetlo
          </h4>
        </div>

        {/* BODY */}
        <div className="flex h-full w-full flex-grow flex-col p-6 pt-10">
          {questionState === "question" ? (
            <div className="flex w-full flex-col place-content-center items-center gap-6 rounded-md bg-card p-10">
              <QuestionTimer
                countdownStartFrom={countdown}
                countdownEndAt={-ANSWER_REVEAL_COUNTDOWN}
                countdown={countdown}
                onCountdownUpdate={(count) => {
                  setCountDown(count);

                  // Display result timer once question timer ends
                  if (count === 0) {
                    setQuestionState("resultTimer");
                  }
                  // Display answer once question timer ends
                  else if (count === 0) {
                    setRevealAnswer(true);
                  }
                  // Check if game is over otherwise display next question timer
                  else if (count === -ANSWER_REVEAL_COUNTDOWN) {
                    console.log("ANSWER REVEAL COUNTDOWN");
                    // Game over if answer is wrong
                    if (
                      selectedOption &&
                      selectedOption !== question?.correctAnswer
                    ) {
                      console.log("GAME LOST");
                      setIsGameOver(true);
                      return;
                    }
                    // Display next question timer if next question is available
                    else if (nextQuestion) {
                      setQuestionState("nextQuestionTimer");
                      setCountDown(RESULT_TIMER_COUNTDOWN);
                      return;
                    }
                    // Game over if next question is not available
                    else {
                      console.log("GAME OVER");
                      setIsGameOver(true);
                      if (isContinueWatching) {
                        router(`/game/${gameId}/winners`);
                      }
                    }
                  }
                  console.log(count);
                }}>
                {!revealAnswer ? (
                  <div className="flex h-14 w-14 place-content-center items-center rounded-full bg-primary/10 font-semibold ring-8 ring-primary">
                    {countdown}
                  </div>
                ) : (
                  !isContinueWatching && (
                    <AnswerStatus
                      selectedOption={selectedOption}
                      correctAnswer={question?.correctAnswer}
                    />
                  )
                )}
              </QuestionTimer>

              <p className="py-6 text-center text-2xl font-semibold">
                {question?.question}
              </p>
              <ul className="flex w-full flex-col gap-2">
                {question?.options?.map((option, index) => (
                  <OptionItem
                    key={index}
                    option={option}
                    onSelect={setSelectedOption}
                    isSelected={selectedOption === option}
                    isCorrect={
                      (option === selectedOption && revealAnswer) ||
                      (revealAnswer && option === question?.correctAnswer)
                    }
                    isSelectionLocked={revealAnswer || isContinueWatching}
                    isWrong={
                      selectedOption === option &&
                      option !== question?.correctAnswer &&
                      revealAnswer
                    }
                  />
                ))}
              </ul>
            </div>
          ) : questionState === "resultTimer" ? (
            <ResultWaitTimer
              countdown={RESULT_TIMER_COUNTDOWN}
              selectedOption={selectedOption}
              onResultTimerEnd={() => {
                setQuestionState("question");
                setRevealAnswer(true);
                setCountDown(-1);
              }}
            />
          ) : (
            questionState === "nextQuestionTimer" && (
              <NextQuestionWaitTimer
                countdown={NEXT_QUESTION_TIMER_COUNTDOWN}
                onNextQuestion={() => {
                  console.log("\n🚀 Display Next Question");
                  setQuestionState("question");
                  setSelectedOption(undefined);
                  setRevealAnswer(false);
                  setCountDown(QUESTION_TIMER_COUNTDOWN);
                  dispatch(setQuestion());
                }}
              />
            )
          )}
        </div>
      </div>
    </MobilePageLayout>
  );
}

function OptionItem({
  option,
  onSelect = () => {},
  isSelected,
  isCorrect,
  isWrong,
  isSelectionLocked,
}: {
  option: string;
  onSelect: (option: string) => void;
  isSelected: boolean;
  isCorrect: boolean;
  isWrong: boolean;
  isSelectionLocked: boolean;
}) {
  return (
    <li
      className={cn(
        clsx(
          "flex w-full cursor-pointer justify-between rounded-lg border border-primary/10 font-bold",
          "transition-all duration-300 ease-in-out",
          "hover:bg-primary/10 hover:text-secondary-foreground",
          "active:scale-95",

          {
            "bg-primary/40 ring-2 ring-primary hover:bg-primary/40": isSelected,
            "hover:bg-primary/30": isSelected && isSelectionLocked,

            "bg-green-500 ring-green-500 hover:bg-green-400": isCorrect,
            "ring-green-500 hover:bg-green-500": isCorrect && isSelectionLocked,

            "bg-red-500 text-secondary ring-red-500 hover:bg-red-400": isWrong,
            "hover:bg-red-500 hover:text-secondary hover:ring-red-500":
              isWrong && isSelectionLocked,

            "cursor-not-allowed active:scale-100": isSelectionLocked,
          }
        )
      )}>
      <button
        className={clsx("flex w-full p-2", {
          "cursor-not-allowed": isSelectionLocked,
        })}
        disabled={isSelectionLocked}
        onClick={() => {
          if (!isSelectionLocked) {
            onSelect(option);
          }
        }}>
        <span>{option}</span>
      </button>
    </li>
  );
}

interface QuestionTimerProps {
  onCountdownUpdate?: (count: number) => void;
  countdownStartFrom?: number;
  countdownEndAt?: number;
  countdown: number;
  children?: React.ReactNode;
}
function QuestionTimer({
  onCountdownUpdate = (count: number) => {},
  countdownEndAt = 0,
  countdown,
  children,
}: QuestionTimerProps) {
  useEffect(() => {
    // 5 seconds
    const interval = setInterval(() => {
      onCountdownUpdate(countdown - 1);

      if (countdown === countdownEndAt) {
        clearInterval(interval);
        onCountdownUpdate(countdownEndAt);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countdown, countdownEndAt, onCountdownUpdate]);
  return <>{children}</>;
}
