import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpClient } from "lib/http-client";

export const getProfileAsync = createAsyncThunk(
  "user/getProfile",
  async () => {
    const resource = await httpClient.GET<any>(
      `/auth/profile`,
    );
    return resource.data;
  }
);

export const updateProfileAsync = createAsyncThunk("user/updateProfile",
  async ({ name, username, picture }: { name: string, username: string, picture?: any }) => {
    const data = new FormData();
    data.append('name', name);
    data.append('username', username);
    if (picture) {
      data.append('picture', picture);
    }
    const resource = await httpClient.POST<any>(
      `/auth/profile`,
      data,
    );
    return resource.data;
  }
);