import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { useToast } from "hooks/use-toast";
import { ArrowLeftIcon } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { loginWithMobileAsync } from "store/reducers/auth/auth.action";
import Loader from "ui/components/loader.atom";
import { Button } from "ui/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "ui/components/ui/form";
import { Input } from "ui/components/ui/input";
import MobilePageLayout from "ui/layout/mobile-page-layout";
import { z } from "zod";

const FormSchema = z.object({
  phoneNumber: z.string().min(10, {
    message: "Your mobile number must be 10 characters.",
  }),
});

export default function MobileAuthPage() {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const { toast } = useToast();

  const auth = useAppSelector((state) => state.auth);
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      phoneNumber: "",
    },
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    console.log(JSON.stringify(data, null, 2));
    dispatch(loginWithMobileAsync({ phoneNumber: data.phoneNumber })).then(
      (result) => {
        if (loginWithMobileAsync.fulfilled.match(result)) {
          console.log("success");
          router("/auth/verify-otp");
        } else if (loginWithMobileAsync.rejected.match(result)) {
          toast({
            title: "Error",
            description: result.error.message ?? "Something went wrong",
            variant: "destructive",
          });
        }
      }
    );
  }

  return (
    <MobilePageLayout>
      <div className="m-4">
        <Link to={"../"}>
          <Button
            variant={"outline"}
            size={"icon"}
            className="my-4 h-7 w-11 rounded-full">
            <ArrowLeftIcon size={15} />
          </Button>
        </Link>

        <div className="mx-auto flex max-w-80 flex-col">
          <div className="py-10">
            <h1 className="text-xl font-medium">
              Please enter your mobile no.
            </h1>
            <p className="text-sm">You’ll use this to login later</p>
          </div>
          <div className="my-10 flex flex-col gap-24">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="flex flex-col space-y-20">
                <FormField
                  control={form.control}
                  name="phoneNumber"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          placeholder="Enter Mobile no."
                          className="max-w-80"
                          autoFocus
                          required
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button type="submit">
                  {auth.status === "loading" ? <Loader /> : "Next"}
                </Button>
              </form>
            </Form>
          </div>
        </div>
      </div>
    </MobilePageLayout>
  );
}
