import { cn } from "lib/utils";
import React from "react";
import { useEffect, useState } from "react";

interface CountDownTimerProps {
  startTime?: string;
  onCountdownEnd: () => void;
  className?: string;
  componentBeforeCountdownStart?: React.ReactNode;
  onFifteenSecondsLeft?: () => void;
}

export function GameStartCountDownTimer({
  startTime,
  onCountdownEnd,
  componentBeforeCountdownStart,
  className,
  onFifteenSecondsLeft,
}: CountDownTimerProps) {
  const [countdown, setCountdown] = useState(0);
  useEffect(() => {
    if (!startTime) return;
    const interval = setInterval(() => {
      const time = startTime
        ? Math.floor(
            (new Date(startTime).getTime() - new Date().getTime()) / 1000
          )
        : 0;
      if (time === 15 && onFifteenSecondsLeft) {
        onFifteenSecondsLeft();
      }
      if (time > 0) {
        setCountdown(time);
      } else {
        setCountdown(0);
        clearInterval(interval);
        onCountdownEnd();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [startTime, onCountdownEnd, onFifteenSecondsLeft]);
  if (!startTime) {
    return <></>;
  }
  // Display component before countdown starts if countdown is greater than 600 seconds
  if (countdown > 600 && componentBeforeCountdownStart) {
    return <>{componentBeforeCountdownStart}</>;
  } else if (countdown < 1) {
    return <div className="h-56"></div>;
  }
  return (
    <div
      className={cn(
        `flex flex-col place-content-center items-center`,
        className
      )}>
      <p className="text-xl font-semibold">Starts in</p>
      <div className="flex items-center gap-2 font-bold">
        <span>
          {Math.floor(countdown / 3600)
            .toString()
            .padStart(2, "0")}
        </span>
        <span>:</span>
        <span>
          {Math.floor((countdown % 3600) / 60)
            .toString()
            .padStart(2, "0")}
        </span>
        <span>:</span>
        <span>{(countdown % 60).toString().padStart(2, "0")}</span>
      </div>
    </div>
  );
}
