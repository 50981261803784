import { PhoneCall } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "ui/components/svgs/logo.svg";
import { Button } from "ui/components/ui/button";
import MobilePageLayout from "ui/layout/mobile-page-layout";

export default function GetStartedPage() {
  return (
    <MobilePageLayout>
      <div className="flex min-h-screen flex-col place-content-center items-center">
        <div className="flex flex-col items-center gap-4 pb-20">
          <Logo />
          <h1 className="font-family-app-label mt-4 text-5xl font-bold">
            JEETLO
          </h1>
        </div>

        <div className="flex flex-col items-center gap-4">
          <h2 className="mt-4 text-xl font-medium">Get Started</h2>
          <Link to={"login"}>
            <Button variant={"outline"} className="flex items-center gap-2">
              <PhoneCall size={18} />
              Continue with Mobile
            </Button>
          </Link>
        </div>
      </div>
    </MobilePageLayout>
  );
}
