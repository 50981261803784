import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { getInitials } from "lib/functions/get-initials";
import { ArrowLeft } from "lucide-react";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { updateState } from "store/reducers/game/game.reducer";
import { getGameWinnersAsync } from "store/reducers/game/quiz-result/quiz-result.action";
import { Avatar, AvatarFallback, AvatarImage } from "ui/components/ui/avatar";
import { Button } from "ui/components/ui/button";
import MobilePageLayout from "ui/layout/mobile-page-layout";

export default function GameWinnersPage() {
  const params = useParams();

  const gameId = params.gameId;
  const { gameWinners, winnersStatus } = useAppSelector(
    (state) => state.quizResult
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getGameWinnersAsync({ gameId: gameId! }));
  }, [dispatch, gameId]);
  return (
    <MobilePageLayout
      title="Home"
      isLoading={winnersStatus === "loading"}
      className="bg-gradient-to-b from-primary to-[#282061]">
      <div className="flex min-h-screen flex-col items-center bg-primary p-4">
        {/* Appbar */}
        <div className="flex w-full justify-end text-left">
          <Link
            to="/"
            className="font-family-app-label w-fit text-sm tracking-wider text-secondary">
            Jeetlo
          </Link>
        </div>

        <div className="flex w-full flex-grow flex-col items-center gap-14">
          <div className="w-fit rounded-full bg-green-400 px-4 py-2 font-semibold text-green-900">
            Game Winners
          </div>
          <div className="grid w-full grid-cols-2 gap-10 md:grid-cols-3">
            {Array.isArray(gameWinners?.winners) &&
            gameWinners?.winners?.length! > 0 ? (
              gameWinners?.winners?.map((winner) => (
                <div
                  key={winner.id}
                  className="flex flex-col items-center gap-4">
                  <Avatar className="h-16 w-16">
                    <AvatarImage src={winner.picture} />
                    <AvatarFallback>{getInitials(winner.name)}</AvatarFallback>
                  </Avatar>
                  <div className="flex flex-col items-center text-secondary">
                    <p className="font-semibold">{winner.name}</p>
                    <p className="text-sm">@{winner.username}</p>
                    <p className="font-semibold">
                      ₹{gameWinners?.perWinnerPrize}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-span-2 w-full text-center">
                <p className="py-10 text-secondary">No winners yet</p>
                <Link to="/" className="">
                  <Button
                    variant={"default"}
                    className=""
                    onClick={() => {
                      dispatch(
                        updateState({ game: undefined, status: "failed" })
                      );
                    }}>
                    <ArrowLeft className="mr-2" /> Go to home
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </MobilePageLayout>
  );
}
