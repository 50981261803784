import { useAppSelector } from "hooks/redux-hooks";
import { cn } from "lib/utils";
import React, { useEffect, useState } from "react";
import { GameStartCountDownTimer } from "ui/components/compounds/game-start-countdown";
import MobilePageLayout from "ui/layout/mobile-page-layout";

interface GameStartCountdownProps {
  onCountdownEnd: () => void;
}
export default function GameStartCountdown({
  onCountdownEnd,
}: GameStartCountdownProps) {
  const { game } = useAppSelector((state) => state.game);
  return (
    <MobilePageLayout
      title="Game Start Countdown"
      className="relative bg-gradient-to-b from-primary to-[#282061]"
      innerClassName="min-h-screen bg-primary  text-secondary border border-secondary/5 rounded"
      appbar={
        <div className="h-[48px] w-full p-4">
          <p className="font-family-app-label text-right text-sm">JEETLO</p>
        </div>
      }>
      <GameStartCountDownTimer
        startTime={game?.startTime}
        onCountdownEnd={onCountdownEnd}
        className="gap-10 p-20 text-7xl"
      />
    </MobilePageLayout>
  );
}
