import { QuestionMarkIcon } from "@radix-ui/react-icons";
import { useAppSelector } from "hooks/redux-hooks";
import { getInitials } from "lib/functions/get-initials";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Avatar, AvatarFallback, AvatarImage } from "ui/components/ui/avatar";
import { Button } from "ui/components/ui/button";
import MobilePageLayout from "ui/layout/mobile-page-layout";
import GameStartCountdown from "./game-start-countdown";
import QuestionPage from "./game/question.page";
import Loader from "ui/components/loader.atom";
import { GameStartCountDownTimer } from "ui/components/compounds/game-start-countdown";

export default function HomePage() {
  const { status, user } = useAppSelector((state) => state.user);
  const { game, status: gameStatus } = useAppSelector((state) => state.game);
  const question = useAppSelector((state) => state.question);

  // Display full screen countdown if 15 seconds left
  const [displayFullScreenCountdown, setDisplayFullScreenCountdown] =
    useState(false);
  // Display game started screen if game started
  const [gameStarted, setGameStarted] = useState(false);

  function handleCountdownEnd() {
    console.log("Countdown ended");
    setGameStarted(true);
    setDisplayFullScreenCountdown(false);
  }

  if (displayFullScreenCountdown) {
    return <GameStartCountdown onCountdownEnd={handleCountdownEnd} />;
  } else if (gameStarted) {
    return <QuestionPage />;
  }
  return (
    <MobilePageLayout
      title="Home"
      isLoading={status === "loading"}
      className="bg-gradient-to-b from-primary to-[#282061]">
      <div className="relative text-secondary">
        <div className="absolute right-4 top-4">
          <Link to="/guideline">
            <button className="flex h-6 w-6 items-center justify-center rounded-full bg-secondary/40 text-secondary hover:bg-secondary/60 active:scale-95 active:bg-secondary/80 active:text-primary active:shadow-sm">
              <QuestionMarkIcon className="h-3 w-3" />
            </button>
          </Link>
        </div>
        <div className="flex min-h-screen flex-col items-center bg-primary p-4 md:pt-10">
          <div className="py-4 text-center text-xl font-medium">
            <h1 className="font-family-app-label text-4xl tracking-wider text-secondary">
              Jeetlo
            </h1>
          </div>
          {gameStatus === "loading" && (
            <div className="flex h-48 flex-col place-content-center items-center gap-4 text-center text-secondary">
              <p className="text-lg">Checking for new game..</p>
              <p className="text-lg">
                <Loader />
              </p>
            </div>
          )}
          <GameStartCountDownTimer
            onFifteenSecondsLeft={() => {
              console.log("15 seconds left");
              setDisplayFullScreenCountdown(true);
            }}
            className="my-10 gap-2 text-4xl"
            onCountdownEnd={handleCountdownEnd}
            startTime={game?.startTime}
            componentBeforeCountdownStart={
              gameStatus === "success" && (
                <div className="flex flex-col items-center gap-2 py-10 text-secondary">
                  <p className="text-md">NEXT GAME</p>
                  <div className="flex flex-col items-center">
                    {/* Display start date if game start tomorrow or later */}
                    {game?.startTime &&
                      game.startTime > new Date().toISOString() && (
                        <p className="text-2xl">
                          {new Date(game.startTime).toLocaleDateString(
                            "en-IN",
                            {
                              day: "2-digit",
                              month: "long",
                              year: "numeric",
                            }
                          )}
                        </p>
                      )}
                    {/* Display start time if game start time is not midnight */}
                    {game?.startTime && (
                      <p className="text-2xl">
                        {new Date(game.startTime).toLocaleTimeString("en-IN", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </p>
                    )}
                  </div>
                  <p className="text-2xl"></p>
                  <p className="text-2xl">₹{game?.prizeMoney} prize </p>
                </div>
              )
            }
          />
          {/* </div> */}

          {gameStatus === "failed" && (
            <div className="flex h-48 flex-col justify-center text-center text-secondary">
              <p className="text-lg">No Game is available right now.</p>
              <p className="text-lg">Please check back later.</p>
            </div>
          )}

          <div className="mx-auto flex aspect-square w-full max-w-sm flex-col place-content-between items-center rounded-2xl bg-card py-4 text-secondary-foreground shadow-md">
            <div className="flex flex-grow flex-col place-content-center items-center gap-4">
              <Avatar className="h-20 w-20">
                <AvatarImage src={user?.picture} />
                <AvatarFallback>{getInitials(user?.name)}</AvatarFallback>
              </Avatar>
              <h1 className="text-xl">{user?.username}</h1>
            </div>
            <div className="flex w-full flex-grow flex-col place-content-center items-center gap-4 border-t border-border">
              <p>BALANCE </p>
              <p className="text-3xl font-bold">₹ 0</p>
            </div>
          </div>

          <Button className="absolute bottom-5 left-0 right-0 mx-auto max-w-sm rounded-full bg-secondary/15 hover:bg-secondary/20">
            <Link to="/auth">
              <h1 className="">Invite</h1>
            </Link>
          </Button>
        </div>
      </div>
    </MobilePageLayout>
  );
}
