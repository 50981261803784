import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import React from "react";
import {
  getQuestionAsync,
  submitAnswerAsync,
} from "store/reducers/game/question/question.action";
import { CountDownTimer } from "ui/components/compounds/countdown-timer";

interface ResultWaitTimerProps {
  countdown: number;
  selectedOption?: string;
  onResultTimerEnd: () => void;
}
export default function ResultWaitTimer({
  countdown,
  selectedOption,
  onResultTimerEnd,
}: ResultWaitTimerProps) {
  const { status, index } = useAppSelector((state) => state.question);
  const game = useAppSelector((state) => state.game);
  const gameId = game?.game?.id;
  const dispatch = useAppDispatch();

  function getNextQuestion() {
    dispatch(
      submitAnswerAsync({
        gameId: gameId!,
        index: index - 1!,
        answer: selectedOption!,
      })
    );

    if (
      status !== "gettingNextQuestion" &&
      (game.game?.totalQuestionCount ?? 0) > index
    ) {
      dispatch(
        getQuestionAsync({
          index: index,
          gameId: gameId!,
        })
      );
    }
  }

  return (
    <div className="flex h-full w-full flex-grow flex-col place-content-center items-center gap-4 text-7xl text-secondary">
      <p className="text-2xl">Result in</p>
      <CountDownTimer
        countdownStartFrom={countdown}
        onCountdownUpdate={(count) => {
          console.log("ResultIn", count);
          if (count === 3) {
            getNextQuestion();
          } else if (count === 0) {
            onResultTimerEnd();
          }
        }}
      />
    </div>
  );
}
