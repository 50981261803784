import { useAppSelector } from "hooks/redux-hooks";
import React from "react";
import { CountDownTimer } from "ui/components/compounds/countdown-timer";
interface NextQuestionWaitTimerProps {
  countdown: number;
  onNextQuestion: () => void;
}
export default function NextQuestionWaitTimer({
  countdown,
  onNextQuestion,
}: NextQuestionWaitTimerProps) {
  const { nextQuestion } = useAppSelector((state) => state.question);
  return (
    <div className="flex h-full w-full flex-grow flex-col place-content-center items-center gap-4 text-7xl text-secondary">
      <p className="text-2xl">Next Question in</p>
      <CountDownTimer
        countdownStartFrom={countdown}
        onCountdownUpdate={(count) => {
          console.log("NextQuestionWaitTimer", { count, nextQuestion });
          if (count === 0 && nextQuestion) {
            onNextQuestion();
          }
        }}
      />
    </div>
  );
}
