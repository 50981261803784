import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { useToast } from "hooks/use-toast";
import { ArrowLeftIcon } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { updateProfileAsync } from "store/reducers/user/user.action";
import Loader from "ui/components/loader.atom";
import { Button } from "ui/components/ui/button";
import { FileInput } from "ui/components/ui/file-input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "ui/components/ui/form";
import { Input } from "ui/components/ui/input";
import MobilePageLayout from "ui/layout/mobile-page-layout";
import { z } from "zod";

const FormSchema = z.object({
  name: z.string().min(3, {
    message: "Your name must be 3 characters.",
  }),
  username: z
    .string()
    .min(3, {
      message: "Your username must be 3 characters.",
    })
    .max(50, {
      message: "Your username must be less than 50 characters.",
    }),
});

export default function UpdateProfilePage() {
  const dispatch = useAppDispatch();
  const [picture, setPicture] = React.useState<File | null>(null);
  const { toast } = useToast();
  const router = useNavigate();

  const { updateStatus } = useAppSelector((state) => state.user);
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
      username: "",
    },
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    dispatch(
      updateProfileAsync({
        name: data.name,
        username: data.username,
        picture: picture,
      })
    ).then((result) => {
      if (updateProfileAsync.fulfilled.match(result)) {
        toast({
          title: "Success",
          description: "Profile updated successfully",
          variant: "success",
        });
        router("/guideline");
      } else if (updateProfileAsync.rejected.match(result)) {
        toast({
          title: "Error",
          description: result.error.message ?? "Something went wrong",
          variant: "destructive",
        });
      }
    });
  }

  return (
    <MobilePageLayout>
      <div className="m-4">
        <Link to={"../"}>
          <Button
            variant={"outline"}
            size={"icon"}
            className="my-4 h-7 w-11 rounded-full">
            <ArrowLeftIcon size={15} />
          </Button>
        </Link>

        <div className="mx-auto flex max-w-80 flex-col">
          <div className="py-10">
            <h1 className="text-xl font-medium">Create Profile</h1>
          </div>
          <div className="my-10 flex flex-col gap-24">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="flex flex-col space-y-6">
                <div className="flex place-content-center items-center">
                  <div className="flex flex-col items-center gap-2">
                    <FormLabel>Profile Picture</FormLabel>
                    <div className="flex items-center gap-4">
                      <FileInput
                        accept="image/*"
                        onChange={(file) => {
                          console.log(file);
                          setPicture(file as File);
                        }}>
                        <div className="h-20 w-20 rounded-lg border border-border">
                          {picture ? (
                            <img
                              src={URL.createObjectURL(picture)}
                              alt="profile"
                              className="h-full w-full rounded-lg object-cover"
                            />
                          ) : (
                            <div className="flex h-full w-full flex-col place-content-center items-center">
                              <p className="text-xs">Upload</p>
                              <p className="text-xs">picture</p>
                            </div>
                          )}
                        </div>
                      </FileInput>
                    </div>
                  </div>
                </div>
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Enter Name</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Full Name"
                          className="max-w-80"
                          // maxLength={10}
                          required
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="username"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Username</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="@username"
                          className="max-w-80"
                          // maxLength={10}
                          required
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="pt-10">
                  <Button type="submit" className="w-full">
                    {updateStatus === "loading" ? <Loader /> : "Next"}
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </div>
      </div>
    </MobilePageLayout>
  );
}
